<template>
  <c-box flex-grow="1">
    <c-breadcrumb
      font-size="14px"
      line-height="21px"
      separator="›"
      :d="['none', 'block']"
    >
      <c-breadcrumb-item>
        <c-breadcrumb-link
          color="primary.400"
          as="router-link"
          to="/management/diet-program"
        >
          Manajemen Program
        </c-breadcrumb-link>
      </c-breadcrumb-item>
      <c-breadcrumb-item>
        <c-breadcrumb-link is-current>
          {{ id ? "Edit" : "Tambah" }} Diet Program
        </c-breadcrumb-link>
      </c-breadcrumb-item>
    </c-breadcrumb>
    <c-divider my="16px" />
    <c-heading
      as="h3"
      font-family="Montserrat"
      font-weight="bold"
      font-size="24px"
      line-height="36px"
      mb="50px"
    >
      {{ id ? "Edit" : "Tambah" }} Diet Program
    </c-heading>

    <form
      v-chakra
      max-w="590px"
    >
      <c-flex
        align="baseline"
        mb="25px"
      >
        <c-form-control
          min-w="180px"
          w="180px"
          mr="40px"
          :is-invalid="true"
        >
          <c-form-label
            font-size="14px"
            color="#555555"
            font-weigh="400"
            font-family="Roboto"
          >
            Gambar Program
          </c-form-label>
          <label
            v-chakra="{
              bg: 'white',
              cursor: 'pointer',
              border: '1px solid #C4C4C4',
              boxSizing: 'border-box',
              borderRadius: '6px',
              w: '180px',
              h: '180px',
              d: 'flex',
              p: '0',
              alignItems: 'center',
              justifyContent: 'center',
              overflow: 'hidden',
            }"
            for="image-program"
          >
            <c-image
              :src="getPreviewImage(photoUrl)"
              :w="
                !getPreviewImage(photoUrl).includes('icon-photo')
                  ? '100%'
                  : '94px'
              "
              alt="img program"
              mx="auto"
              border-radius="6px"
            />
          </label>
          <c-input
            id="image-program"
            style="display: none"
            type="file"
            accept=".jpg, .jpeg, .png"
            error-border-color="red.300"
            placeholder="Masukkan Deskripsi Konten"
            height="62px"
            @change="onFileChange"
          />
        </c-form-control>
        <c-form-control
          align-self="center"
          w="100%"
          :is-invalid="true"
        >
          <c-form-label
            font-size="14px"
            color="gray.900"
            font-weigh="normal"
            font-family="Roboto"
          >
            File maksimal 3 MB
          </c-form-label>
        </c-form-control>
      </c-flex>
      <c-form-control
        mb="25px"
        is-required
        :is-invalid="name === ''"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Nama Program
        </c-form-label>
        <c-input-group size="md">
          <c-input
            v-model="name"
            type="text"
            error-border-color="red.300"
            placeholder="Masukkan Nama Program"
            height="62px"
          />
        </c-input-group>
      </c-form-control>

      <c-form-control
        mb="25px"
        is-required
        :is-invalid="overview === ''"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Overview
        </c-form-label>
        <textarea
          v-model="overview"
          v-chakra="{
            border: '1px solid #C4C4C4',
            padding: '18px',
            width: '100%',
            borderRadius: '6px',
            '&:focus, &:focus-visible': {
              outline: 'unset',
            },
          }"
          rows="3"
          placeholder="Masukkan Overview"
        />
      </c-form-control>

      <c-form-control
        mb="25px"
        is-required
        :is-invalid="description === ''"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Deskripsi
        </c-form-label>
        <textarea
          v-model="description"
          v-chakra="{
            border: '1px solid #C4C4C4',
            padding: '18px',
            width: '100%',
            borderRadius: '6px',
            '&:focus, &:focus-visible': {
              outline: 'unset',
            },
          }"
          rows="3"
          placeholder="Masukkan Deskripsi"
        />
      </c-form-control>

      <c-form-control
        mb="25px"
        is-required
        :is-invalid="suitableFor === ''"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Program Ini Cocok
        </c-form-label>
        <textarea
          v-model="suitableFor"
          v-chakra="{
            border: '1px solid #C4C4C4',
            padding: '18px',
            width: '100%',
            borderRadius: '6px',
            '&:focus, &:focus-visible': {
              outline: 'unset',
            },
          }"
          rows="3"
          placeholder="Masukkan Program Ini Cocok"
        />
      </c-form-control>

      <c-form-control
        mb="25px"
        is-required
        :is-invalid="programTarget === ''"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Target Program
        </c-form-label>
        <textarea
          v-model="programTarget"
          v-chakra="{
            border: '1px solid #C4C4C4',
            padding: '18px',
            width: '100%',
            borderRadius: '6px',
            '&:focus, &:focus-visible': {
              outline: 'unset',
            },
          }"
          rows="3"
          placeholder="Masukkan Target Program"
        />
      </c-form-control>

      <c-form-control
        mb="25px"
        is-required
        :is-invalid="serviceFacility === ''"
      >
        <c-form-label
          font-size="14px"
          color="#555555"
          font-weigh="400"
          font-family="Roboto"
        >
          Fasilitas Program
        </c-form-label>
        <textarea
          v-model="serviceFacility"
          v-chakra="{
            border: '1px solid #C4C4C4',
            padding: '18px',
            width: '100%',
            borderRadius: '6px',
            '&:focus, &:focus-visible': {
              outline: 'unset',
            },
          }"
          rows="3"
          placeholder="Masukkan Fasilitas Program"
        />
      </c-form-control>

      <c-heading
        as="h3"
        font-family="Montserrat"
        font-weight="bold"
        font-size="24px"
        line-height="36px"
        color="primary.400"
        mb="25px"
      >
        Layanan Program
      </c-heading>

      <c-box
        v-for="(item, index) in productServices"
        v-show="!item.status"
        :key="'service' + index"
        position="relative"
        :border-top="index > 0 ? 'thin solid #f2f2f2' : ''"
        :mt="index > 0 ? '25px' : ''"
        :pt="index > 0 ? '25px' : ''"
      >
        <c-box
          position="absolute"
          top="40px"
          right="-45px"
        >
          <c-button
            ml="18px"
            min-width="auto"
            variant-color="red"
            variant="link"
            @click="onDeletService(item, index)"
          >
            <c-image
              :src="require('@/assets/icon-trash-line.svg')"
              alt="empty"
            />
          </c-button>
        </c-box>
        <c-form-control
          :is-invalid="item.programsService === ''"
          is-required
          mb="25px"
        >
          <c-form-label
            font-size="14px"
            color="#555555"
            font-weigh="400"
            font-family="Roboto"
          >
            Nama Layanan
          </c-form-label>
          <c-input-group size="md">
            <c-input
              v-model="item.programsService"
              type="text"
              error-border-color="red.300"
              placeholder="Masukkan Nama Layanan"
              height="62px"
            />
          </c-input-group>
        </c-form-control>

        <c-form-control
          :is-invalid="item.duration === ''"
          is-required
          mb="25px"
        >
          <c-form-label
            font-size="14px"
            color="#555555"
            font-weigh="400"
            font-family="Roboto"
          >
            Durasi Layanan (Menit)
          </c-form-label>
          <c-input-group size="md">
            <c-input
              v-model="item.duration"
              type="number"
              min="0"
              error-border-color="red.300"
              placeholder="Masukkan Durasi Layanan"
              height="62px"
            />
          </c-input-group>
        </c-form-control>

        <c-form-control
          :is-invalid="item.price === ''"
          is-required
          mb="25px"
        >
          <c-form-label
            font-size="14px"
            color="#555555"
            font-weigh="400"
            font-family="Roboto"
          >
            Harga
          </c-form-label>
          <c-input-group size="md">
            <c-input-left-addon height="62px">
              Rp
            </c-input-left-addon>
            <c-input
              v-model="item.price"
              type="number"
              min="0"
              error-border-color="red.300"
              placeholder="Masukkan Harga"
              height="62px"
            />
          </c-input-group>
        </c-form-control>

        <c-form-control
          mb="25px"
          is-required
          :is-invalid="item.serviceTarget === ''"
        >
          <c-form-label
            font-size="14px"
            color="#555555"
            font-weigh="400"
            font-family="Roboto"
          >
            Target Layanan
          </c-form-label>
          <textarea
            v-model="item.serviceTarget"
            v-chakra="{
              border: '1px solid #C4C4C4',
              padding: '18px',
              width: '100%',
              borderRadius: '6px',
              '&:focus, &:focus-visible': {
                outline: 'unset',
              },
            }"
            rows="3"
            placeholder="Masukkan Target Layanan"
          />
        </c-form-control>
      </c-box>

      <c-button
        :is-disabled="disabledAddProductService"
        variant-color="primary"
        variant="outline"
        border-radius="60px"
        h="62px"
        w="100%"
        mb="25px"
        left-icon="add"
        @click="addProductService"
      >
        Tambah Program Layanan Lain
      </c-button>

      <c-flex
        mt="28px"
        justify-content="space-between"
      >
        <c-button
          variant="solid"
          variant-color="gray"
          border-radius="60px"
          w="50%"
          h="62px"
          mr="20px"
          font-size="18px"
          color="#555555"
          @click="onCancel"
        >
          Batal
        </c-button>
        <c-button
          variant="solid"
          variant-color="primary"
          border-radius="60px"
          w="50%"
          h="62px"
          font-size="18px"
          :is-disabled="isDisabledSubmit || disabledAddProductService"
          @click="!id ? onSubmit() : (isOpen = true)"
        >
          Simpan
        </c-button>
      </c-flex>
    </form>

    <c-alert-dialog
      :is-open="isOpen"
      :least-destructive-ref="$refs.cancelRef"
    >
      <c-alert-dialog-overlay z-index="1" />
      <c-alert-dialog-content
        text-align="center"
        z-index="2"
        w="488px"
        top="40px"
        py="40px"
        px="40px"
        box-shadow="4px 4px 50px 5px rgba(0, 0, 0, 0.1)"
        border-radius="12px"
      >
        <c-image
          w="176px"
          mx="auto"
          mb="49px"
          :src="require('@/assets/icon-confirm-update.svg')"
        />
        <c-alert-dialog-body
          text-align="center"
          mb="35px"
          font-weight="500"
          font-size="18px"
          line-height="27px"
          v-html="'Apakah anda yakin ingin melakukan <br/> perubahan data?'"
        />
        <c-alert-dialog-footer p="0">
          <c-button
            ref="cancelRef"
            w="50%"
            h="auto"
            py="17px"
            border-radius="60px"
            @click="isOpen = false"
          >
            Batal
          </c-button>
          <c-button
            w="50%"
            h="auto"
            py="17px"
            border-radius="60px"
            variant-color="primary"
            ml="3"
            min-w="80px"
            @click="onSubmit"
          >
            Simpan
          </c-button>
        </c-alert-dialog-footer>
      </c-alert-dialog-content>
    </c-alert-dialog>
  </c-box>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import generalMixin from '@/utils/general-mixins'

export default {
  name: 'SuperadminDietProgramForm',
  components: {},
  mixins: [generalMixin],
  data() {
    return {
      name: null,
      overview: null,
      photoUrl: null,
      productServices: [
        {
          programsService: null,
          duration: 0,
          price: 0,
          serviceTarget: null,
        },
      ],
      description: null,
      suitableFor: null,
      programTarget: null,
      serviceFacility: null,
      isUploading: false,
      isOpen: false,
    }
  },
  computed: {
    ...mapState({
      item: (s) => s.suManagementProgram.item,
    }),
    id() {
      return this.$route.params.id
    },
    disabledAddProductService() {
      let emptyObj =
        this.productServices.findIndex(
          (dt) =>
            !dt.programsService ||
            !dt.duration ||
            !dt.price ||
            !dt.serviceTarget,
        ) > -1
      if (emptyObj) return true
      return false
    },
    isDisabledSubmit() {
      if (
        !this.name ||
        !this.overview ||
        !this.photoUrl ||
        this.productServices.length === 0 ||
        !this.description ||
        !this.suitableFor ||
        !this.programTarget ||
        !this.serviceFacility
      ) {
        return true
      }
      return false
    },
  },
  watch: {
    item: {
      handler(val) {
        if (val) {
          this.name = val.name
          this.overview = val.overview
          this.photoUrl = val.photoUrl
          this.productServices = val.productServices
          this.description = val.description
          this.suitableFor = val.suitableFor
          this.programTarget = val.programTarget
          this.serviceFacility = val.serviceFacility
        }
      },
    },
  },
  async mounted() {
    if (!this.id) return
    this.detailProgram(this.id)
  },
  methods: {
    ...mapActions({
      createProgram: 'suManagementProgram/createProgramSuperadmin',
      detailProgram: 'suManagementProgram/detailProgramSuperadmin',
      updateProgram: 'suManagementProgram/updateProgramSuperadmin',
    }),
    onDeletService(item, index) {
      let self = this
      item.status = 'deleted'
      let newItem = item
      self.productServices.splice(index, 1)
      self.productServices.push(newItem)
    },
    onFileChange(input) {
      if (this.isUploading) return
      if (input.target.files && input.target.files[0]) {
        let file = input.target.files[0]
        this.isUploading = true
        this.$toast({
          description: 'Uploading...',
          status: 'info',
          duration: null,
          position: 'top',
          variant: 'subtle',
        })
        if (file.type.includes('image') && file.size > 2097152) {
          this.isUploading = false
          return this.$toast({
            title: 'Failed',
            description: `This file can't be uploaded, because it (${this.formatBytes(
              file.size,
            )}) exceeds the maximum file size (2 MB).`,
            status: 'error',
            duration: 10000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        }
        // Upload avatar to API
        let formData = new FormData()
        formData.append('file', input.target.files[0])
        for (var pair of formData.entries()) {
          console.log(pair[0] + ', ' + pair[1])
        }

        this.axios
          .post('/v1/super-admin/products/upload', formData)
          .then((it) => it.data)
          .then((it) => {
            this.isUploading = false
            this.photoUrl = it.data.url
          })
          .catch(() => {
            this.$toast({
              title: 'Failed',
              description: 'Ops! Something when wrong.',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
          })
      }
    },
    getPreviewImage(url) {
      if (url) {
        return url
      }
      return require('@/assets/icon-photo.svg')
    },
    addProductService() {
      this.productServices.push({
        programsService: null,
        duration: 0,
        price: 0,
        serviceTarget: null,
      })
    },
    onSubmit() {
      let params = {
        name: this.name,
        overview: this.overview,
        photoUrl: this.photoUrl,
        productServices: this.productServices,
        description: this.description,
        suitableFor: this.suitableFor,
        programTarget: this.programTarget,
        serviceFacility: this.serviceFacility,
      }
      if (this.id) {
        params.id = this.id
        return this.updateProgram(params)
          .then(() => {
            this.$toast({
              title: 'Success',
              description: 'Update diet program is successfully',
              status: 'success',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
            this.$router.push({
              name: 'superadmin.diet-program.detail',
              params: { id: this.id },
            })
          })
          .catch((err) => {
            this.$toast({
              title: 'Failed',
              description: err.data.message ?? 'Ops! Something when wrong.',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
          })
      }
      return this.createProgram(params)
        .then(() => {
          this.$toast({
            title: 'Success',
            description: 'Create diet program is successfully',
            status: 'success',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
          this.$router.push({
            name: 'superadmin.diet-program',
            query: { type: this.type },
          })
        })
        .catch((err) => {
          this.$toast({
            title: 'Failed',
            description: err.data.message ?? 'Ops! Something when wrong.',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        })
    },
    onCancel() {
      if (this.id) {
        this.$router.push({
          name: 'superadmin.diet-program.detail',
          params: { id: this.id },
        })
      } else {
        this.$router.push({ name: 'superadmin.diet-program' })
      }
    },
  },
}
</script>
